import { PAGES } from "@/utils/pages";
import { getServerSession } from "next-auth";
import { GetServerSideProps } from "next/types";
import { authOptions } from "./api/auth/[...nextauth]";
import Layout from "@/components/Layout";
import Button from "@/components/Button";
import { ArrowPathIcon, PresentationChartLineIcon } from "@heroicons/react/24/solid";
import ImageCarousel from "@/components/ImageCarousel";
import { useRouter } from "next/router";

const DEMO_LINK = "https://calendly.com/ayazhafiz/30min";

export default function Index() {
  const router = useRouter();
  let style = router.query.style ?? "normal";
  if (Array.isArray(style)) {
    style = style[0];
  }

  return (
    <Layout title="Treater">
      <div className="grow text-left [&>*]:py-12 md:[&>*]:py-16 pt-8 md:pt-0">
        <Hero style={style} />
        <Customers />
        <ValueProposition />
        <Team />
        <ValueInsights />
      </div>
    </Layout>
  );
}

function Hero({ style }: { style: string }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-0">
      <div className="flex flex-col justify-center md:col-span-2 items-center">
        <div className="text-center md:text-left space-y-8 sm:space-y-4 max-w-xl px-2">
          {style === "pokemon" ? (
            <h1 className="text-5xl lg:text-6xl leading-[3rem] font-bold tracking-tight text-primaryText">
              Give your retail sales a{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-main-300 to-main-500">
                moonstone
              </span>{" "}
              <picture className="inline">
                <img
                  src="https://archives.bulbagarden.net/media/upload/3/32/Dream_Moon_Stone_Sprite.png"
                  alt="pokemon"
                  className="h-10 inline"
                />
              </picture>
            </h1>
          ) : (
            <h1 className="text-5xl lg:text-6xl leading-[3rem] font-bold tracking-tight text-primaryText">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-main-300 to-main-500">
                Multiply
              </span>{" "}
              your retail sales{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-main-300 to-main-500">
                effortlessly
              </span>{" "}
            </h1>
          )}
          <p className="max-w-2xl text-lg leading-6 text-primaryText">
            Treater identifies and captures revenue opportunities in real-time. No merchandising
            reps or manual lift required.
          </p>
          <Button href={DEMO_LINK}>Book a demo</Button>
        </div>
      </div>
      <div className="md:col-span-2 overflow-x-auto md:overflow-x-hidden max-w-full flex items-center">
        <picture>
          <img
            src="/treater-dash-demo.png"
            alt="Treater demo dashboard"
            className="h-[350px] object-cover object-left-top w-auto max-w-none md:max-w-auto"
          />
        </picture>
      </div>
    </div>
  );
}

function ValueInsights() {
  return (
    <div className="px-4 text-center space-y-8 bg-slate-50">
      <div className="text-center space-y-4 max-w-3xl mx-auto">
        <p className="text-3xl font-medium">
          Make the most of your shelf space. <b className="text-main-500">Try Treater.</b>
        </p>
        <Button href={DEMO_LINK}>Book a demo</Button>
      </div>
    </div>
  );
}

function ValueProposition() {
  return (
    <div className="px-4 lg:px-16 flex flex-col lg:flex-row w-screen gap-8 lg:gap-0 justify-around">
      <div className="lg:w-1/2 text-center p-4 space-y-4 max-w-xl">
        <h3 className="text-2xl text-center">Fast setup. No maintenance. Automatic sales.</h3>
        <p className="">
          Use Treater for periodic reports on driven sales, as a full-blown co-pilot with your sales
          team, or anything in between.
        </p>
        <div className="flex flex-col md:flex-row gap-2 text-center pt-4 items-center justify-center">
          <Pill>
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-main-300 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-main-400"></span>
            </span>{" "}
            Always online
          </Pill>
          <Pill>
            <PresentationChartLineIcon className="h-3 w-3 text-blue-400" />
            Accurate forecasts
          </Pill>
          <Pill>
            <ArrowPathIcon className="h-3 w-3 text-blue-400" />
            Fully automated
          </Pill>
        </div>
      </div>
      <div className="lg:w-1/2 text-center p-4 space-y-4 max-w-xl">
        <h3 className="text-2xl">Zero-lift integrations</h3>
        <p className="">
          Connects to existing data from any distributor or retailer. More integrations added every
          day. All with industry-leading data protection.
        </p>
        <div className="pt-4">
          <ImageCarousel
            animationDuration="15s"
            height="h-20"
            imageUrls={[
              {
                src: "https://upload.wikimedia.org/wikipedia/en/1/17/UNFI_Logo_2019.png",
                alt: "UNFI",
              },
              {
                src: "https://s.yimg.com/ny/api/res/1.2/JTHm0lqEJmD9E0J7u3khgQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD0zMDM-/https://media.zenfs.com/en/globenewswire.com/ec99c158773691d28b3b6feb9331d1c5",
                alt: "KeHE",
              },
              {
                src: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Whole_Foods_Market_logo.svg/2560px-Whole_Foods_Market_logo.svg.png",
                alt: "Whole Foods Market",
              },
              {
                src: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Target_logo.svg/1541px-Target_logo.svg.png",
                alt: "Target",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function Team() {
  return (
    <div className="px-4 lg:px-16 w-screen gap-8 lg:gap-0 flex justify-center">
      <div className="lg:w-1/2 text-center p-4 space-y-4">
        <div className="max-w-xl text-center mx-auto">
          <h3 className="text-2xl text-center">Backed by strong CPG and tech operators</h3>
          <p className="">
            We built Treater because we know how difficult it is to merchandise your doors. Our team
            is made up of operators from CPG brands, and experienced engineers.
          </p>
        </div>
        <div className="pt-4 max-w-3xl mx-auto">
          <ImageCarousel
            height="h-12"
            animationDuration="20s"
            imageUrls={[
              {
                src: "https://d3k81ch9hvuctc.cloudfront.net/company/HUMGJc/images/767b9032-eb2b-4361-9625-7af320f51915.png",
                alt: "Cometeer",
              },
              {
                src: "https://1000logos.net/wp-content/uploads/2021/09/McKinsey-Logo.png",
                alt: "McKinsey & Company",
              },
              {
                src: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/220px-Google_2015_logo.svg.png",
                alt: "Google",
              },
              {
                src: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Lyft_logo.svg/220px-Lyft_logo.svg.png",
                alt: "Lyft",
              },
              {
                src: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/D._E._Shaw_%26_Co._logo.png/800px-D._E._Shaw_%26_Co._logo.png",
                alt: "D. E. Shaw & Co.",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function Pill({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-fit items-center gap-x-2 px-2 py-1 shadow-sm border">{children}</div>
  );
}

function Customers() {
  return (
    <div className="bg-slate-50">
      <div className="px-4 text-center space-y-8 max-w-3xl mx-auto">
        <p className="text-2xl sm:text-4xl">
          Trusted by fast-moving CPG brands to drive <span className="text-primary-400">50%+</span>{" "}
          in sales and optimize growth.
        </p>
        <p className="text-lg">
          Treater&apos;s AI-powered data analysis and autonomous agents lift sales in your stores by
          filling voids, ensuring promo execution, and much more.
        </p>
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions);

  if (session) {
    return {
      redirect: { destination: PAGES.ORGANIZATIONS, permanent: false },
    };
  }

  return {
    props: {},
  };
};
